import Locale from '../types/models/locale';

export const DEFAULT_LOCALE = 'en';

export const languagesList: Locale[] = [
  {
    id: 'en',
    short: 'EN',
    label: 'English',
  },
  {
    id: 'ru',
    short: 'RU',
    label: 'Русский',
  },
  {
    id: 'uk',
    short: 'UA',
    label: 'Українська',
  }
];
