import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import App from './App';

// Initialize languages
import './locales/i18n';


// styles
import './index.scss';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ParallaxProvider>
  </React.StrictMode>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
