// core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {useTranslation} from "react-i18next";
// utils

// locales

// store

// types

// components
import Logo from "../../icons/logo";
import ThemeToggler from '../ThemeToggler'
import LangToggler from "../LangToggler";
import HeaderNav from "./HeaderNav";
import LogoAvatar from "../../icons/logoAvatar";

// styles
import './index.scss';

// data

// func
const Header = () => {
  // translation hook
  const {t} = useTranslation();

  return (
    <header className='header'>
      <div className='app__wrapper'>
        <div className='header__content'>
          <div className='header__logo'>
            <RouterLink to={'/lp'}>
              <Logo />
            </RouterLink>
          </div>
          <div className='header__nav'>
            <HeaderNav/>
          </div>
          <div className='header__actions'>
            <div className='header__lang-toggler'>
              <ThemeToggler/>
            </div>
            <div className='header__theme-toggler'>
              <LangToggler/>
            </div>
            <div className='header__btns'>
              <a href='https://geo-pay.net/auth/log-in'
                      className='app__btn--outline header__btn header__btn--sign'>
                <LogoAvatar/>
                {t('actions.login')}
              </a>
              <a href='https://geo-pay.net/auth/sign-up'
                 className='app__btn header__btn header__btn--create'>
                {t('actions.signin')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
