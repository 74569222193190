// core
import React, {Suspense, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

// utils
import ScrollToTop from './utils/scroll-to-top';

// layouts
import StaticLayout from './components/layouts/StaticLayout';
import MainLayout from './components/layouts/MainLayout';

// modules
import Loader from "./components/atoms/Loader";

import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';

const Home = React.lazy(() => import("./modules/Home/index"));
const Wallet = React.lazy(() => import("./modules/Wallet/index"));
const ArbitrationTools = React.lazy(() => import("./modules/ArbitrationTools/index"));
const P2PTrade = React.lazy(() => import("./modules/P2PTrade/index"));
const FiatGateway = React.lazy(() => import("./modules/FiatGateway/index"));
const UAHGToken = React.lazy(() => import("./modules/UAHGToken/index"));
const Partners = React.lazy(() => import("./modules/Partners/index"));

function App() {
  useEffect(()=>{
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-out-cubic'
    });
  },[]);

  return (
    <Suspense fallback={<Loader />}>
      <ScrollToTop />
      <Routes>
        <Route path="/lp" element={<MainLayout><Home/></MainLayout>}/>

        <Route path="/lp/fiat-gateway" element={<StaticLayout><FiatGateway/></StaticLayout>}/>
        <Route path="/lp/arbitration-tools" element={<StaticLayout><ArbitrationTools/></StaticLayout>}/>
        <Route path="/lp/wallet" element={<StaticLayout><Wallet/></StaticLayout>}/>
        <Route path="/lp/p2p-trade" element={<StaticLayout><P2PTrade/></StaticLayout>}/>
        <Route path="/lp/uahg-token" element={<StaticLayout><UAHGToken/></StaticLayout>}/>
        <Route path="/lp/partners" element={<StaticLayout><Partners/></StaticLayout>}/>

        <Route path="*" element={<MainLayout><Home/></MainLayout>}/>
      </Routes>
    </Suspense>
  );
}

export default App;

