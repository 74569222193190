// core
import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

// components
import Logo from '../../icons/logo'

// styles
import './index.scss'

// data
import {FOOTER_SOCIAL, FOOTER_DOCS} from './data'


const Footer = () => {
  const {t} = useTranslation();

  const openFreshChat = () => {
    //@ts-ignore
    window.fcWidget.open();
  }

  return (
    <footer className='footer'>
      <div className='app__wrapper'>
        <div className='footer__grid'>
          <div className='footer__left'>
            <div className='footer__logo'>
              <a href='/' title="GEO Pay">
                <Logo/>
              </a>
            </div>
          </div>
          <div className='footer__right'>
            <div className='footer__right-item'>
              <div className='footer__title'>
                {t('footer.social.title')}
              </div>
              <ul>
                {FOOTER_SOCIAL.map((item, index) => (
                  <li key={index}>
                    <a href={item.url}
                       title={item.name}
                       className='app__link'
                       target='_blank' rel="noreferrer"
                    >{<item.icon/>}{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__right-item'>
              <div className='footer__title'>
                {t('footer.geoPay.title')}
              </div>
              <ul>
                <li>
                  <RouterLink to={'/lp/vacancies'} title={t('footer.geoPay.item1')}
                              className='app__link'>
                    {t('footer.geoPay.item1')}
                  </RouterLink>
                </li>
                <li>
                  <a href='https://blog.geo-pay.net/'
                     title={t('footer.geoPay.item2')}
                     target='_blank'
                     className='app__link' rel="noreferrer">
                    {t('footer.geoPay.item2')}
                  </a>
                </li>
                <li>
                  <a href='https://geo-pay.net/support/faq'
                     title={t('footer.geoPay.item3')}
                     className='app__link'>
                    {t('footer.geoPay.item3')}
                  </a>
                </li>

              </ul>
            </div>
            <div className='footer__right-item'>
              <div className='footer__title'>
                {t('footer.support.title')}
              </div>
              <ul>
                <li>
                  <a href='https://t.me/geopaysupport_bot '
                     title={t('footer.support.item1')}
                     className='app__link' target='_blank' rel="noreferrer">
                    {t('footer.support.item1')}
                  </a>
                </li>
                <li >
                  <span onClick={openFreshChat}
                        title={t('footer.support.item2')}
                        className='app__link'>
                     {t('footer.support.item2')}
                  </span>
                </li>
              </ul>
            </div>
            <div className='footer__right-item'>
              <div className='footer__title'>
                {t('footer.documents.title')}
              </div>
              <ul>
                {FOOTER_DOCS.map((item, index) => (
                  <li key={index}>
                    <a href={item.url} title={item.name} className='app__link'>
                      {t(item.name)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

